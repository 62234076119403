<script>
    let { css = '' } = $props()
</script>

<svg
    xmlns="http://www.w3.org/2000/svg"
    class="group-focus:stroke-3 group-hover:stroke-3 h-5 flex-shrink-0 stroke-2 {css}"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
>
    <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M6 18L18 6M6 6l12 12"
    />
</svg>
